import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "normalize.css";
import "@/global.css";
import "swiper/css";
import { ConfigBaseProvider } from "./contexts/configBase.context";
import { PictureProvider } from "./contexts/pic.context";
import { HeaderProvider } from "./contexts/header.context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

function ctrlShiftKey(e: any, keyCode: any) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

// Disable right-click
document.addEventListener('contextmenu', (e) => e.preventDefault());

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, 'I') ||
    ctrlShiftKey(e, 'J') ||
    ctrlShiftKey(e, 'C') ||
    (e.altKey && e.keyCode === 73) ||
    (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
  )
    return false;
};

root.render(
  <React.StrictMode>
    <HeaderProvider>
      <ConfigBaseProvider>
        <PictureProvider>
          <App />
        </PictureProvider>
      </ConfigBaseProvider>
    </HeaderProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
