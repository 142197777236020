import { FC } from "react";
import styles from "./HotArea.module.css";
import { usePictureIndex } from "@/contexts/pic.context";
import { useNavigate } from "react-router-dom";
import Image from "../image/Image";

const HotArea: FC = () => {
  const navigate = useNavigate();
  const { categories } = usePictureIndex();

  const handleCategoryClick = (item: any) => {
    navigate(`/page/category/${item.jump_name}?name=${item.name}&page=1`);
  };

  return (
    <div className={styles.hotArea}>
      {/* <div className={styles.hotAreaTitle}>热門专区</div> */}
      <div className={styles.hotAreaContent}>
        {categories &&
          categories.map((item, index) => {
            return (
              <div
                className={styles.contentItem}
                key={index}
                onClick={() => handleCategoryClick(item)}
              >
                <div className={styles.itemImg}>
                  <Image
                    key={index}
                    srcValue={item && item.icon}
                    className={styles.hotAreaImg}
                    size={true}
                  />
                </div>
                {/* <div className={styles.itemName}>{item.name}</div> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default HotArea;
