import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import useRequest from "@/hooks/useRequest";

import BackButton from "@/components/backButton/BackButton";
import Image from "@/components/image/Image";
import u from "@/util";

import styles from "./PicDetail.module.css";
import { useConfigBase } from "@/contexts/configBase.context";
import Post from "@/components/post/Post";
import { useHeader } from "@/contexts/header.context";
import Wrapper from "@/components/wrapper/Wrapper";

interface IPicDetail {
  title: string;
  tags: string;
  insert_time: number;
  thumb: string;
  content: [];
}
const PicDetail: FC = () => {
  const navigate = useNavigate();
  const { jump_name = "" } = useParams();
  const { configBaseList } = useConfigBase();
  const { setIsMobileHeaderChildOpen } = useHeader();
  let [searchParams] = useSearchParams();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [picDetail, setPicDetail] = useState<IPicDetail>();
  const [imageList, setImageList] = useState<string[]>([]);
  const [picRandomList, setPicRandomList] = useState([]);

  const [imageDecryptList, setImageDecryptList] = useState<string[]>([]);

  const { makeRquest: requestDetail } = useRequest("tupian/detail", "post");
  const { makeRquest: requestMeinvDetail } = useRequest("meinv/detail", "post");
  const { makeRquest: requestRandom } = useRequest("pic/random-tupian", "post");
  const { makeRquest: requestMeinvRandom } = useRequest(
    "pic/random-meinv",
    "post"
  );

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const fmtImageList = () => {
    let newFormatList: string[] = [];

    imageList &&
      imageList.forEach(async (item, index) => {
        const imageUrlKey = configBaseList.image_url || "";
        const encryptUrls = `${imageUrlKey}/${item}.txt`;

        const res = await u.fetchData(encryptUrls);

        newFormatList[index] = res;
      });

    setImageDecryptList(newFormatList);
  };

  const fetchDetail = useCallback(async () => {
    try {
      const {
        data: { info },
      } = await requestDetail({
        id: searchParams.get("id"),
      });

      setImageList(info.content);
      setPicDetail(info || {});
    } catch (err) {
      console.log(err);
    }
  }, [requestDetail, searchParams]);

  const fetchMeinvDetail = useCallback(async () => {
    try {
      const {
        data: { info },
      } = await requestMeinvDetail({
        id: searchParams.get("id"),
      });

      setImageList(info.content);
      setPicDetail(info || {});
    } catch (err) {
      console.log(err);
    }
  }, [requestMeinvDetail, searchParams]);

  const fetchRandom = useCallback(async () => {
    try {
      const {
        data: { random },
      } = await requestRandom({
        name: jump_name || "",
      });
      const randomNum = u.randomNumberInRange(1, 10);
      const isArr = random[0] instanceof Array;
      if (isArr) {
        return setPicRandomList(random[randomNum]);
      }
      setPicRandomList(random);
    } catch (err) {
      console.log(err);
    }
  }, [searchParams]);

  const fetchMeinvRandom = useCallback(async () => {
    const cat_id = parseInt(searchParams.get("meinv") || "1");
    try {
      const {
        data: { random },
      } = await requestMeinvRandom({
        id: cat_id,
      });
      const randomNum = u.randomNumberInRange(1, 10);
      const isArr = random[0] instanceof Array;
      if (isArr) {
        return setPicRandomList(random[randomNum]);
      }
      setPicRandomList(random);
    } catch (err) {
      console.log(err);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("meinv") != null) {
      fetchMeinvDetail();
    } else {
      fetchDetail();
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("meinv") != null) {
      fetchMeinvRandom();
    } else {
      fetchRandom();
    }
  }, [searchParams]);

  const handlePostOnClick = (postValue: any) => {
    const channel = (postValue && postValue.channel) || "";
    const id = (postValue && postValue.id) || 1;
    if (channel === "meinv") {
      // navigate(`/page/meinv/chapter?id=${id}&page=1`);
      navigate(
        `/page/category/jpmv/detail?id=${id}&meinv=${postValue.cat_id || 1}`
      );
    } else {
      navigate(`/page/category/${jump_name}/detail?id=${id}`);
    }
  };

  useEffect(() => {
    fmtImageList();
  }, [configBaseList, imageList]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [searchParams]);

  useEffect(() => {
    setIsMobileHeaderChildOpen(false);
  }, []);

  return (
    <Wrapper>
      <div className={styles.picDetailBodyContainer}>
        <BackButton />
        <div className={styles.picDetailContent}>
          <div className={styles.picDetailActorContainer}>
            <div className={styles.picDetailActor}>
              <div className={styles.actorProfile}>
                <Image
                  srcValue={(picDetail && picDetail.thumb) || ""}
                  className={styles.iCvr}
                />
                <Image
                  srcValue={(picDetail && picDetail.thumb) || ""}
                  className={styles.actorProfileImage}
                />
              </div>
              <div className={styles.picDetailActorDesc}>
                <p className={styles.title}>
                  {(picDetail && picDetail.title) || "-"}
                </p>
                <div className={styles.desc}>
                  <p className={styles.category}>
                    类别：{(picDetail && picDetail.tags) || "-"}
                  </p>
                  <p className={styles.time}>
                    更新时间：{" "}
                    {(picDetail && u.toFmt(picDetail.insert_time * 1000)) ||
                      "-"}
                  </p>
                </div>
                <div className={styles.labelViews}>
                  {/* <div className={styles.label}>
      <img src={pic} alt="pic" />
      <p>12P</p>
    </div> */}
                  <div className={styles.views}>
                    {/* <img src={eye} alt="eye" /> */}
                    <p>
                      {picDetail &&
                        picDetail.content &&
                        picDetail.content.length}
                      P
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.picDetailPreviewImage}>
              {picDetail &&
                picDetail.content.map((src, index) => {
                  // const imageUrlKey = configBaseList.image_url || "";
                  // const encryptUrls = `${imageUrlKey}/${src}.txt`;
                  return (
                    <div
                      key={index}
                      onClick={() => openImageViewer(index)}
                      className={styles.picDetailPreviewImageContainer}
                    >
                      <Image srcValue={src} className={styles.iCvr} />
                      <Image
                        srcValue={src}
                        className={styles.picDetailPreviewImage}
                      />
                    </div>
                  );
                })}

              {isViewerOpen && (
                <ImageViewer
                  src={imageDecryptList}
                  currentIndex={currentImage}
                  onClose={closeImageViewer}
                  disableScroll={true}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 100,
                  }}
                  // className={styles.ImageViewer}
                  closeOnClickOutside={true}
                />
              )}
            </div>
            <div className={styles.detailRecommend}>
              <div className={styles.recommendTitle}>猜你喜欢</div>
              <div className={styles.detailRecommendPostList}>
                {picRandomList &&
                  picRandomList.map((val: any) => (
                    <Post
                      post={val}
                      key={val.id}
                      onClick={handlePostOnClick}
                    ></Post>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PicDetail;
