import axios, { InternalAxiosRequestConfig } from "axios";
import u, { ELocalKey } from "@/util";
import { PROD_SITE } from "@/const/prod.const";
import { useState } from "react";

interface IUseRequestConfig extends InternalAxiosRequestConfig {
  __endpoint?: string;
}

// axios.defaults.headers.common["suffix"] = 123456;
axios.defaults.headers.post['Content-Type'] = 'text/plain';

axios.interceptors.request.use(
  (config: IUseRequestConfig) => {
    const val =
      u.getLocalData(ELocalKey._BASE)?.config?.json_host ||
      "https://utt.51jiajiao.top/";

    if (!val) return config;

    let _url = `/${config?.__endpoint}`;

    if (_url && _url.indexOf("config/base") >= 0) {
      config.url = `${val}data${_url}-${PROD_SITE}.js`;
      config.method = "get";
    }

    if (_url && _url === "/pic/index") {
      config.url = `${val}data/site_pic${_url}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/index-list") >= 0) {
      config.url = `${val}data/site_pic/pic/index_list.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/list-tupian") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { name: "", page: 1});
      config.url = `${val}data/site_pic/pic/list_tupian-${reqData.name || ''}-${reqData.page}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/list-meinv") >= 0) {
      config.url = `${val}data/site_pic/pic/list_meinv.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/chapter-meinv") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { id: 1});
      config.url = `${val}data/site_pic/pic/chapter_meinv-${reqData.id || 1}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("tupian/detail") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { id: 1});
      config.url = `${val}data/site_pic${_url}-${reqData.id}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("meinv/detail") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { id: 1});
      config.url = `${val}data/site_pic${_url}-${reqData.id}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/random-tupian") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { name: "" });
      config.url = `${val}data/site_pic/pic/random_tupian-${reqData.name}.js`;
      config.method = "get";
    }

    if (_url && _url.indexOf("pic/random-meinv") >= 0) {
      const reqData = JSON.parse(u.decrypt(config.data['post-data']) || { id: 1 });
      config.url = `${val}data/site_pic/pic/random_meinv-${reqData.id}.js`;
      config.method = "get";
    }

    if (config.url && config.url.indexOf("172.247.9.210:8901") >= 0) {
      config.headers['suffix'] = 123456;
      config.headers["Content-Type"] = "application/json"
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const useRequest = (url: string, method: any, noEncrypt: boolean = false) => {
  const [error, setError] = useState("");

  let baseUrl = "http://172.247.9.210:8901/api"; //"https://site11api.beladns.com/api";

  const makeRquest = async (data?: any) => {
    let payload = {} as any;

    payload["system"] = u.isMobile() ? 2 : 1;
    payload["timestamp"] = new Date().getTime();
    payload = { ...payload, ...data };

    if (!noEncrypt) {
      payload["encode_sign"] = u.base64Sign(payload);
      payload = { "post-data": u.encrypt(JSON.stringify(payload)) };
    }

    try {
      const response = await axios.request({
        url: `${baseUrl}/${url}`,
        data: payload,
        method,
        __data: payload,
        __endpoint: url,
      } as any);

      let __data = u.decrypt(response.data.data, response.data.suffix);
      __data = JSON.parse(__data);
      response.data.data = __data;

      return response.data;
    } catch (err: any) {
      setError(err.message);
    }
  };

  return { makeRquest, error };
};

export default useRequest;
